import { useEffect, useState } from "react";

export default function useMousePosition() {
    const [mousePosition, setMousePosition] = useState({ left: null, top: null });

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
            const mouseMoveHandler = (e) => {
                if (isSafari()) {
                    setMousePosition({ left: `${e.clientX - 32}px`, top: `${e.clientY - 32}px` });
                    return;
                }
                setMousePosition({ left: `${e.clientX}px`, top: `${e.clientY}px` });
            };
            window.addEventListener("mousemove", mouseMoveHandler);

            return () => {
                window.removeEventListener("mousemove", mouseMoveHandler);
            };
        }
    }, []);

    return mousePosition;
}