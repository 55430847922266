import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container } from 'react-bootstrap';
import ModalMenuBtn from "./modalMenuBtn";
import Cursor from "./Cursor";

const HeaderStatic = () => {

    return (
        <Container fluid className="header header-static  px-2 px-sm-3 px-lg-4 px-xl-5 py-3">
            <Link to="/">
                <Cursor />
                <StaticImage src="../images/shapes/Group 9.png" alt="logo" />
            </Link>
            <ModalMenuBtn />
        </Container>
    )
}

export default HeaderStatic;
