import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container } from 'react-bootstrap';
import { Parallax } from "react-scroll-parallax";
import { Link } from "gatsby";

// import Cursor from './Curssor'

export default function Footer() {
    return (
        <Parallax speed={25}>
            <Container fluid className="footer bg-dark p-4 p-md-5  d-flex justify-content-center justify-content-md-between flex-column flex-md-row text-light">
                <Link to="/" className="d-flex justify-content-center mb-4">
                    <StaticImage
                        src="../images/shapes/Frame-1.png" alt="logo"
                        className="footerLogo"
                    />
                </Link>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column  flex-lg-row justify-content-lg-between align-items-center mb-4" >
                            <h4 className="my-3">
                                Kifisias 23, 14561<br />Athens, Greece
                            </h4>
                            <h4 className="text-end my-3">
                                +306944578173<br />info@arche.gr
                            </h4>
                        </div>
                        <h4 className="d-flex flex-column flex-lg-row align-items-center mb-4">
                            <span>Contact hours:&nbsp;</span>
                            <span>MONDAY - FRIDAY&nbsp;</span>
                            <span>10:00 - 17:00</span>
                        </h4>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <a href="https://www.facebook.com/arche2design" className="me-4 hoverUp" target="_blank" rel="noreferrer">
                                <StaticImage src="../images/Vector.png" alt="facebook link icon" />
                            </a>
                            <a href="https://www.instagram.com/kondylis_nycdesignhouse/" className="me-4 hoverUp" target="_blank" rel="noreferrer">
                                <StaticImage src="../images/Group.png" alt="instagram link icon" />
                            </a>
                            <a href="https://www.linkedin.com/in/theodore-kondylis-7a6a85a3/" className="hoverUp" target="_blank" rel="noreferrer">
                                <StaticImage src="../images/in-icon.png" alt="linkedin link icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </Parallax>

    )
}
