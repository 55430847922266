import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import ModalMenuBtn from "./modalMenuBtn";
import Cursor from './Cursor';

const Header = () => {
  return (
    <Container fluid className="header header-nonStatic px-2 px-sm-3 px-lg-4 px-xl-5 pt-4">
      <Row className="d-flex justify-content-between">
        <Col md={3}>
          <Link to="/">
            <Cursor />
            <StaticImage src="../images/shapes/Frame.png" alt="logo" />
          </Link>
        </Col>

        <Col md={8} className="border-top border-primary border-5 d-flex flex-column">
          <Col className="d-flex py-4">
            <Col as="h4">
              Kifisias 23, 14561<br />Athens, Greece
            </Col>
            <Col as="h4">
              +306944578173<br />info@arche.gr
            </Col>
            <ModalMenuBtn />
          </Col>
          <Col as="h4" className="headerText">THEODORE KONDYLIS Architecture, interior designer Theodore Kondylis with MA, Derby university has been at the forefront of the commercial design, showcasing the newest projects in New York, Miami and Raleigh.A veteran in the commercial design with over 20 years ofdesigning experience, Kondylis seeks to fulfill his vision to successfully market his work all over the globe.</Col>
          <Col className="d-flex justify-content-end">
            <Link to="/about" className="headerAboutArrow forCustomCursor">
              <Cursor />
              <StaticImage src="../images/shapes/rightArrow.svg" alt="link arrow" />
            </Link>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default Header
