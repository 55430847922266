import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet";
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "./header"
import HeaderStatic from "./headerStatic";
import Footer from "./footer"
import Cursor from "./Cursor"
import "../styles/main.scss";

const Layout = ({ children, location }) => {
  const [offset, setOffset] = useState(typeof window !== `undefined` ? window.pageYOffset : null)
  const [innerWidth, setInnerWidth] = useState(typeof window !== `undefined` ? window.innerWidth : null)
  const [mainMarginTop, setMainMarginTop] = useState({ marginTop: "0px" })
  const [staticHeaderStyles, setStaticHeaderStyles] = useState({})
  const [nonStaticHeaderStyles, setNonStaticHeaderStyles] = useState({})

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', () => setInnerWidth(window.innerWidth))
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
      return () => window.removeEventListener('resize', () => setInnerWidth(window.innerWidth))
    }
  }, [])

  useEffect(() => {
    if (location === "/" && innerWidth > 1200) {
      // setMainMarginTop({ marginTop: "248px" })
      setMainMarginTop({ marginTop: "521px" })
    } else {
      setMainMarginTop({ marginTop: "0px" })
    }
  }, [location, innerWidth])

  useEffect(() => {
    if (location === "/" && (offset <= 440 && innerWidth > 1200)) {
      setStaticHeaderStyles({ display: "none" })
      setNonStaticHeaderStyles({ display: "block" })
    } else {
      setStaticHeaderStyles({ display: "block" })
      setNonStaticHeaderStyles({ display: "none" })
    }
  }, [location, offset, innerWidth])

  return (
    <>
      <Helmet>
        <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=kondylisnycdesign"></script>
      </Helmet>
      <div style={nonStaticHeaderStyles}>
        <Header siteTitle="Kondylis NYC" id='nonStaticHeader' className="d-none d-xl-block" />
      </div>
      <div style={staticHeaderStyles}>
        <HeaderStatic />
        {location === "/" &&
          <div className="px-2 px-sm-3 px-lg-4 px-xl-5 pb-3 bg-white">
            <h4 className="headerTextMobile py-3">THEODORE KONDYLIS Architecture, interior designer Theodore Kondylis with MA, Derby university has been at the forefront of the commercial design, showcasing the newest projects in New York, Miami and Raleigh.A veteran in the commercial design with over 20 years ofdesigning experience, Kondylis seeks to fulfill his vision to successfully market his work all over the globe.</h4>
            <div className="d-flex justify-content-end">
              <Link to="/about" className="me-3 headerAboutArrow">
                <StaticImage src="../images/shapes/rightArrow.svg" alt="link arrow" />
                <Cursor />
              </Link>
            </div>
          </div>
        }
      </div>
      <main className="bg-light px-2 px-sm-3 px-lg-4 px-xl-5" style={mainMarginTop}>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
