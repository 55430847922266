import React from "react";
import useMousePosition from "../hooks/useMousePosition";

const Cursor = ({ light }) => {
    const mousePosition = useMousePosition();

    return (
        <div
            style={{ top: mousePosition.top, left: mousePosition.left, borderColor: light ? "#f6f6f6" : "1d1d1b" }}
            className="cursor"
        ></div>
    );
}

export default Cursor;