import React, { useState } from 'react'
import { Modal, Container, Col, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Cursor from './Cursor';

const ModalMenuBtn = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Col className="menu-nav-button" onClick={() => setShowModal(true)}>
        <Col as='h3' className="menu-nav-text">MENU</Col>
        <Col as='h3' className="menu-nav-plus">+</Col>
      </Col>
      <Modal show={showModal} fullscreen onHide={() => setShowModal(false)}>
        <Modal.Header className='px-2 px-sm-3 px-lg-4 px-xl-5 pb-0 bg-dark text-light justify-content-end'>
          <Button className="modal-menu-nav-plus" onClick={() => setShowModal(false)}>+</Button>
        </Modal.Header>
        <Modal.Body className='px-4 px-xl-5 pt-sm-4 pt-md-5 bg-dark text-light'>
          <Container fluid className='d-flex d-flex flex-column flex-sm-row'>
            <div>
              <Link className='menuLink text-light' to="/"> <h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>HOME</span></h2><Cursor light /></Link>
              <div className='d-none d-sm-flex flex-column'>
                <Link className='menuLink text-light' to="/about"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>ABOUT</span></h2><Cursor light /></Link>
                <Link className='menuLink text-light' to="/articles"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>NEWS & PRESS</span></h2><Cursor light /></Link>
                <Link className='menuLink text-light' to="/contact"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>CONTACT US</span></h2><Cursor light /></Link>
              </div>
            </div>
            <div className='portfolioMenu ms-sm-4 ms-md-5'>
              <Link onClick={() => setShowModal(false)} className='menuLink text-light' to="/projects"><h2><span className='menuLinkItem'>PORTFOLIO</span></h2><Cursor light /></Link>
              <Link onClick={() => setShowModal(false)} className='menuLink text-light' to="/projects?category=bakery-coffee-deli"><h4 className='ms-3'><span className='menuLinkItem'>Bakery, coffee & deli</span></h4><Cursor light /></Link>
              <Link onClick={() => setShowModal(false)} className='menuLink text-light' to="/projects?category=fb-projects"><h4 className='ms-3'><span className='menuLinkItem'>F&B Projects</span></h4><Cursor light /></Link>
              <Link onClick={() => setShowModal(false)} className='menuLink text-light' to="/projects?category=fashion-commercial"><h4 className='ms-3'><span className='menuLinkItem'>Fashion & Commercial</span></h4><Cursor light /></Link>
              <Link onClick={() => setShowModal(false)} className='menuLink text-light' to="/projects?category=hospitality-projects"><h4 className='ms-3'><span className='menuLinkItem'>Hospitality Projects</span></h4><Cursor light /></Link>
            </div>
            <div className='d-sm-none'>
              <Link className='menuLink text-light' to="/about"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>ABOUT</span></h2></Link>
              <Link className='menuLink text-light' to="/articles"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>NEWS & PRESS</span></h2></Link>
              <Link className='menuLink text-light' to="/contact"><h2 className='mb-3 mb-sm-4'><span className='menuLinkItem'>CONTACT US</span></h2></Link>
            </div>
          </Container>
        </Modal.Body>
        <StaticImage
          sm={2}
          src='../images/shapes/Group 8.png'
          alt="logo"
          className='menuLogo'
        />
      </Modal>
    </>

  )
}

export default ModalMenuBtn;